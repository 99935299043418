import {Link} from 'gatsby';
import React, {FC, memo} from 'react';
import {arrowDownIcon} from '../../images/svgIcons';
import './styles.scss';

export interface PaginationTypeProps {
  activePage: number;
  totalCount: number;
  itemsPerPage: number;
  showNext?: boolean;
  onChange: (val: number) => void;
  path: string;
}
const Pagination: FC<PaginationTypeProps> = ({activePage, totalCount, itemsPerPage, showNext, onChange, path}) => {
  let paginationItemsCount = Math.ceil(totalCount / itemsPerPage);
  if (showNext && activePage >= paginationItemsCount) {
    paginationItemsCount = activePage + 1;
  }

  const PaginationItems = (paginationItemsCount: number) => {
    let itemCount: any = [];
    let leftDots = false;
    let RightDots = false;

    const filterFunc = (item: any) => {
      if (activePage <= 4 && item <= 6) return true;
      if (activePage > paginationItemsCount - 4 && item > paginationItemsCount - 6) return true;
      return (
        item === activePage ||
        item === activePage - 1 ||
        item === activePage + 1 ||
        item === activePage - 2 ||
        item === activePage + 2
      );
    };

    if (paginationItemsCount > 7) {
      if (activePage > 4) leftDots = true;
      if (activePage <= paginationItemsCount - 4) RightDots = true;

      if (activePage < paginationItemsCount - 4 && activePage > 4) {
        itemCount = [
          1,
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
      if (activePage >= paginationItemsCount - 4 && activePage > 4) {
        itemCount = [
          1,
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
      if (activePage <= paginationItemsCount - 4 && activePage <= 4) {
        itemCount = [
          ...Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(filterFunc),
          paginationItemsCount,
        ];
      }
    } else {
      itemCount = Array.from(new Array(paginationItemsCount).keys(), x => x + 1).filter(item => item !== 0);
    }

    return (
      <div className="pagination-items">
        {PaginationLeftOrRightItem('left')}
        {/* @ts-ignore */}
        {[...new Set(itemCount)].map((item: number) => {
          if (item === 0) return null;
          if (
            activePage < paginationItemsCount - 4
              ? item === activePage - 2 && leftDots
              : item ===
                  paginationItemsCount -
                    (paginationItemsCount > 8 && activePage === paginationItemsCount - 4 ? 6 : 5) && leftDots
          ) {
            return PaginationDotsItem();
          }
          if (activePage >= 4 ? item === activePage + 2 && RightDots : item === 6 && RightDots) {
            return PaginationDotsItem();
          }
          return PaginationItem(item);
        })}
        {PaginationLeftOrRightItem('right')}
      </div>
    );
  };

  const PaginationDotsItem = () => {
    return (
      <div className={`pagination-item noselect`}>
        <span className="">...</span>
      </div>
    );
  };

  const PaginationLeftOrRightItem = (side: string) => {
    return (
      <React.Fragment>
        {((side === 'left' && activePage > 1) || (side !== 'left' && activePage < paginationItemsCount)) && (
          <Link
            to={
              side === 'left' && activePage === 2
                ? path
                : `${path}?page=${
                    side === 'left'
                      ? activePage > 1
                        ? activePage - 1
                        : activePage
                      : activePage < paginationItemsCount
                      ? activePage + 1
                      : activePage
                  }`
            }
            className={`pagination-item noselect`}
          >
            <span className={`icon ${side}-icon`}>{arrowDownIcon}</span>
          </Link>
        )}
      </React.Fragment>
    );
  };

  const PaginationItem = (number: number) => {
    return (
      <Link
        to={number === 1 ? path : `${path}?page=${number}`}
        className={`pagination-item ${activePage === number ? 'active' : ''} noselect`}
      >
        <span className="">{number}</span>
      </Link>
    );
  };

  return <div className="pagination">{PaginationItems(paginationItemsCount)}</div>;
};

export default memo(Pagination);
