import React, {useState, FC, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../../layout/layout';
import {PageHero} from '../../page-hero';
import Section from '../../section';
import Input from '../../input';
import NothingToShow from '../../nothingToShow';
import WorkflowCard from '../workflow-card';
import {WorkflowPageContext, WorkflowType} from '../../../types';
import Search from '../../../images/svgIcons/search.svg';
import FooterCTA from '../footer-cta';
import PaginationLink from '../../pagination-link';
import {Link, navigate} from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import './styles.scss';

const Workflows: FC<WorkflowPageContext> = ({pageContext}) => {
  //pageContext
  const workflowsList = pageContext.result.workflows;
  const categoriesList = pageContext.categoriesList?.reverse();
  const category = pageContext.category;
  const selectedCategory = categoriesList.find((item: any) => item.name === pageContext.category);

  const [workflows, setWorkflows] = useState(workflowsList);
  const [inputVal, setInputVal] = useState<string>('');
  const [page, setPage] = useState(
    global && global.window && global.window.location && global.window.location.search
      ? +global.window.location.search.replace('?page=', '') || 1
      : 1,
  );
  const [total, setTotal] = useState(0);
  const search = global.window && global.window.location && global.window.location.search;

  useEffect(() => {
    const num = search && search.replace('?page=', '');
    setPage(num && +num > 1 ? +num : 1);
  }, [search]);

  useEffect(() => {
    if (!inputVal) {
      setTotal(workflowsList.length);
      const finArr = workflowsList;
      setWorkflows(finArr.slice((page - 1) * 12, page * 12));
      return;
    }

    let delayTimer: any;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function() {
      let newWorkflows: Array<WorkflowType> = [];
      workflowsList.map(data => {
        const stepsList = data.steps.find((sub: any) =>
          sub.automation.name.toLowerCase().includes(inputVal.toLowerCase()),
        );
        if ((inputVal && data.name.toLowerCase().includes(inputVal.toLowerCase())) || stepsList) {
          newWorkflows.push(data);
        }
      });

      setTotal(newWorkflows.length);
      const finArr = newWorkflows;
      setWorkflows(finArr.slice((page - 1) * 12, page * 12));
    }, 1000);
  }, [inputVal, page]);

  const handleInputChange = (e: any) => {
    if (!inputVal && e.toLowerCase().trim()) {
      navigate(category ? `/ready-made-workflow-library/${category.toLowerCase()}/` : '/ready-made-workflow-library/');
    }
    setInputVal(e.toLowerCase());
  };

  return (
    <>
      {/* <Helmet>
        <title>{selectedCategory ? selectedCategory.short_description : 'Ready-made workflow library'}</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={
            selectedCategory
              ? selectedCategory.long_description
              : 'Deploy entire automation strategies in minutes to find new leads, perform website audits, reverse engineer SEO strategies or save hours of manual work in a few clicks.'
          }
        />
      </Helmet> */}
      <Layout>
        <PageHero
          title={
            selectedCategory
              ? selectedCategory.short_description
              : 'Workflow templates - Ready to use templates to automate sales, marketing and research tasks'
          }
          subtitle2={
            selectedCategory ? (
              <>
                <p className="m-0">{selectedCategory.long_description1}</p>
                <p className="m-0">{selectedCategory.long_description2}</p>
              </>
            ) : (
              'Deploy entire automation strategies in minutes to find new leads, perform website audits, reverse engineer SEO strategies or save hours of manual work in a few clicks with our pre-built templates.'
            )
          }
          titleSmall
          // noPaddingBottom
          // className="small"
        />
        <Section>
          <div className="search-wrapper col-12">
            <Input
              placeholder="Search Workflow..."
              onChange={e => handleInputChange(e)}
              leftIcon={<img alt="search" src={Search} />}
            />
          </div>
          <div className='d-flex justify-content-center flex-wrap filters-section w-100'>
            <div className="text-center">
              <Link
                to={`/ready-made-workflow-library/`}
                className={`filter-item ${!category ? 'active' : ''}`}
              >
                All
              </Link>
            </div>
            {categoriesList.map((item: any) => (
              <div className="p-0 text-center">
                <Link
                  to={`/ready-made-workflow-library/${item.name.trim().toLowerCase()}`}
                  className={`filter-item ${item.name === category ? 'active' : ''}`}
                >
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="search-property col-12">
            <div className="row">
              {!workflows.length ? (
                <div className="col-12 text-center">
                  <NothingToShow />
                </div>
              ) : (
                workflows.map(item => (
                  <WorkflowCard
                    data={item}
                    state={
                      category
                        ? `/ready-made-workflow-library/${category.toLowerCase()}`
                        : '/ready-made-workflow-library'
                    }
                  />
                ))
              )}
            </div>
          </div>
          {total > 12 ? (
            <div className="d-flex justify-content-center w-100 mt-3">
              <PaginationLink
                activePage={page}
                totalCount={total}
                onChange={setPage}
                itemsPerPage={12}
                path={
                  category ? `/ready-made-workflow-library/${category.toLowerCase()}` : '/ready-made-workflow-library'
                }
              />
            </div>
          ) : (
            <></>
          )}
        </Section>
        <FooterCTA list />
      </Layout>
    </>
  );
};

export default Workflows;
